import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function ProfileHeader({ profileData }) {

    const headerText = JSON.parse(profileData.aboutMe.raw);
    const locale = profileData.node_locale;

    return (
        <section class="">
            <h1 class="font-medium text-2xl">{locale === "fr-CA" ? "Qui-suis je?" : "About me"}</h1>
            <div>
                <div class="h-1 line-separator w-48 my-2 md:my-4"></div>
                <p class="md:text-base text-sm md:break-words break-normal md:mr-4 mr-0">
                    {documentToReactComponents(headerText)}
                </p>
            </div>
        </section>
    )
}