import React from "react"
import ProfileHeader from "./profileHeader"
import ProfileSkills from "./profileSkills"
import ProfileSocial from "./profileSocial"

export default function Profile({ profileData, socialLinks, skills, locale }) {

    return (
        <div className="md:mb-1 mb-4">
            <ProfileHeader profileData={profileData}></ProfileHeader>
            <ProfileSkills skillsData={skills} locale={locale}></ProfileSkills>
            <ProfileSocial socialLinks={socialLinks}></ProfileSocial>
        </div>
    )
}
