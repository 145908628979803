import React from "react";
import ProjectItem from "./projectItem";

export default function Projects({ projectsData, locale }) {
  return (
    <section class="mt-4 mb-2">
      <h1 class="section-header font-medium md:text-3xl text-2xl">
        {locale === "fr-CA" ? 'Projets personnel' : "Personal projects"}
      </h1>
      <div class="h-1 line-separator w-48 my-2 md:my-4"></div>

      {
        projectsData.map((item, index) => {
          return (<ProjectItem projectData={item} />)
        })
      }
    </section>
  )
}