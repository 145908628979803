import React from "react";
import ProfileSocialItem from "./profileSocialItem";


export default function ProfileSocial({ socialLinks }) {
    return (
        <section class="mt-5 md:mt-2 sm:block hidden">
            <h1 class="font-medium text-2xl">Socials</h1>
            <div class="h-1 line-separator w-48 my-2 md:my-4"></div>

            <div class="social-links">
                {
                    socialLinks.map((item, index) => {
                        return (
                            <ProfileSocialItem socialData={item} />
                        )
                    })
                }
            </div>
        </section>
    )
}