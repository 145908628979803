import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function CertificationsItem({ certificationsData }) {

    const { issuer, title, issueDate, issuerWebsite } = certificationsData;
    let description = "";

    if (certificationsData.description) {
        description = documentToReactComponents(JSON.parse(certificationsData.description.raw))
    }

    return (
        <div class="md:my-4 my-2 experience">
            <h2 class="font-medium md:text-2xl text-xl">
                {
                    certificationsData.issuerWebsite != null ?
                        <a href={certificationsData.issuerWebsite} class="hover:underline">{title}</a> :
                        title
                }
            </h2>
            <h3 class="md:text-xl text-base sub-title">
                {issuer}
                <div class="dark:text-orange text-purple inline mx-2">|</div>
                {issueDate}

            </h3>
            <div className="job-description">
                {description}
            </div>


        </div>
    )

}