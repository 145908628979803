import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function ExperienceItem({ experienceData }) {

    const { company, jobTitle, startDate, endDate, visible } = experienceData;
    let jobDescription = "";

    if (experienceData.jobDescription) {
        jobDescription = documentToReactComponents(JSON.parse(experienceData.jobDescription.raw))
    }
    if (visible) {
        return (
            <div class="md:my-4 my-2 experience">
                <h2 class="font-medium md:text-2xl text-xl">{jobTitle}</h2>
                <h3 class="md:text-xl text-base sub-title">

                    {
                        experienceData.url != null ?
                            <a href={experienceData.url} class="hover:underline">{company}</a> :
                            company
                    }<div class="dark:text-orange text-purple inline mx-2">|</div>
                    {
                        endDate === null && experienceData.node_locale === "en-US" ?
                            <i>Currently working since {startDate}</i> :
                            endDate === null && experienceData.node_locale === "fr-CA" ?
                                <i>Emploi actuel depuis {startDate}</i> :
                                <i>{startDate} - {endDate}</i>
                    }


                </h3>
                <div className="job-description">
                    {jobDescription}
                </div>


            </div>
        )
    } else {
        return "";
    }
}