import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function SkillItem(data) {

    const skill = data.skillData;
    const cssClass = "devicon mr-2 " + skill.devicon;

    return (
        <div className="skill-item">
            <a href={skill.url} className="hover:underline">
                <i className={cssClass} />
                {skill.name}
            </a>
        </div>
    )

}