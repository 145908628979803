import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SkillItem from "./skillItem";

export default function ProfileSkills({ skillsData, locale }) {

    return (
        <section class="mt-5 md:mt-2">
            <h1 class="font-medium text-2xl">
                {locale === "fr-CA" ? "Habiletés" : "Skills"}
            </h1>
            <div>
                <div class="h-1 line-separator w-48 my-2 md:my-4"></div>
                <div class="profile-skills">
                    {/* {documentToReactComponents(skills)} */}
                    {
                        skillsData.map((item, index) => {
                            return (
                                <SkillItem skillData={item} />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}