import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faGithub,
    faLinkedin,
    faDev
} from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'
export default function ProfileSocialItem(data) {


    const socialData = data.socialData;
    let icon = faGithub;

    switch (socialData.name) {
        case "Linkedin":
            icon = faLinkedin;
            break;

        case "Github":
            icon = faGithub;
            break;

        case "DEV":
        case "Dev":
            icon = faDev;
            break;
        default:
            icon = faLink;
            break;
    }

    return (
        <a href={socialData.url} class="hover:underline flex items-center">
            <FontAwesomeIcon icon={icon} className="mr-2 social-icon" />
            {socialData.name}
        </a>
    )

}