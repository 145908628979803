import React from "react";
import CertificationsItem from "./certificationsItem";

export default function Certifications({ certificationsData }) {

  return (
    <section class="mb-2">
      <h1 class="section-header font-medium md:text-3xl text-2xl">
        Certifications
      </h1>
      <div class="h-1 line-separator w-48 md:my-4 my-2"></div>
      {
        certificationsData.map((item) => {
          return (<CertificationsItem certificationsData={item} />);
        })
      }

    </section>
  )
}