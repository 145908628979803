import React from "react";
import ExperienceItem from "./experienceItem";

export default function Experience({ experienceData, locale }) {

  return (
    <section class="mb-2">
      <h1 class="section-header font-medium md:text-3xl text-2xl">
        {locale === "fr-CA" ? "Expérience" : 'Experience'}
      </h1>
      <div class="h-1 line-separator w-48 md:my-4 my-2"></div>
      {
        experienceData.map((item, index) => {
          return (<ExperienceItem experienceData={item} />);
        })
      }

    </section>
  )
}