import React from "react";

export default function EducationItem(data) {

    const educationData = data.educationData;
    return (
        <div class="md:my-4 my-2 education">
            <h2 class="font-medium md:text-2xl text-xl">{educationData.degree}</h2>
            <h3 class="md:text-xl text-base sub-title"><a href={educationData.url}
                class="hover:underline">{educationData.schoolName}</a>
                <div class="dark:text-orange text-purple inline mx-2">|</div>
                <i>{educationData.startDate} - {educationData.startDate}</i>
            </h3>
        </div>
    )
}