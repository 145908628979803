import React from "react";
import './education.module.css'
import EducationItem from "./educationItem";

export default function Education({ educationData, locale }) {
    return (
        <section class="mt-4 mb-2">
            <h1 class="section-header font-medium md:text-3xl text-2xl">
                {locale === "fr-CA" ? 'Éducation' : "Education"}
            </h1>
            <div class="h-1 line-separator w-48 my-2 md:my-4"></div>
            {
                educationData.map((item, index) => {
                    return (<EducationItem educationData={item} />);
                })
            }
        </section>
    )
}