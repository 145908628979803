import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

export default function ProjectItem(data) {

    const projectData = data.projectData;
    const projectDescription = JSON.parse(projectData.projectDescription.raw)
    const cssClass = "mr-2 " + projectData.devicon;

    return (
        <div class="md:my-4 my-2 project">
            <a href={projectData.url}>
                <h2 class="font-medium md:text-2xl text-xl">
                    <FontAwesomeIcon icon={faGithub} className="project-icon" />
                    {projectData.name}
                </h2>
            </a>
            <div>
                <h3 class="md:text-xl text-base sub-title">
                    <i className={cssClass} />
                    {projectData.technology}
                </h3>
                {documentToReactComponents(projectDescription)}
            </div>

        </div>
    )
}